import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const SmallStatBox = ({ title, subtitle, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="50%" m="0 15px"> {/* Adjust width as needed */}
      <Box display="flex" flexDirection="column" alignItems="center"> {/* Column-oriented flex container */}
        <Box display="flex" flexDirection="column" alignItems="center"> {/* New Box to wrap icon and title */}
          {icon && (
            <Box mb={1}> {/* Margin bottom for spacing if icon is present */}
              {icon}
            </Box>
          )}
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100] }}
          >
            {title}
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ color: colors.greenAccent[500] }}> {/* Subtitle */}
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default SmallStatBox;
