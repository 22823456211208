import React, { useState } from 'react';
import { Box, Tab, Tabs, IconButton, Typography, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MapComponent from '../../components/MapComponent';
import ChatWindow from '../../components/ChatWindow';
import PropertyWindow from '../../components/PropertyWindow';
import { tokens } from '../../theme';
import { buildings } from '../../data/propertyData';

const Main = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showSideWindow, setShowSideWindow] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleSideWindow = () => {
    setShowSideWindow(!showSideWindow);
  };

  return (
    <Box sx={{ m: 0, p: 0, position: 'relative', height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <MapComponent sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />

      {/* Side Window Toggle Button */}
      <IconButton
        onClick={toggleSideWindow}
        sx={{
          position: 'absolute',
          top: theme.spacing(1),
          left: theme.spacing(1),
          zIndex: 1300,
          color: colors.grey[100],
          bgcolor: colors.primary[400], // Background color to make the button stand out
          '&:hover': {
            bgcolor: colors.primary[600], // Darken the button on hover for better visibility
          },
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* Side Window */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: showSideWindow ? 480 : 0, // Adjust the width as needed
          height: '100%',
          bgcolor: colors.primary[400],
          transition: 'width 0.3s',
          overflow: 'hidden',
        }}
      >
        {/* Tabs at the top of the side window */}
        {showSideWindow && (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
              // Add indicatorColor and textColor properties if necessary
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab 
                label="LLM Chat" 
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'black', // Adjust colors for your theme
                  '&.Mui-selected': {
                    color: theme.palette.mode === 'dark' ? colors.greenAccent[500] : colors.primary[600], // Adjust selected color
                  }
                }} 
              />
              <Tab 
                label="Property Info" 
                sx={{
                  color: theme.palette.mode === 'dark' ? 'white' : 'black', // Adjust colors for your theme
                  '&.Mui-selected': {
                    color: theme.palette.mode === 'dark' ? colors.greenAccent[500] : colors.primary[600], // Adjust selected color
                  }
                }} 
              />
            </Tabs>
            <Box sx={{ flexGrow: 1 }}>
              {activeTab === 0 && <ChatWindow />}
              {activeTab === 1 && <PropertyWindow buildings={buildings}/>}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Main;
