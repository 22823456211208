import { Box, Card, CardMedia, CardContent, Typography } from "@mui/material";

// Component to represent a single leasable space
const LeasableSpace = ({ space }) => {
  const thumbnail = space.photos && space.photos.length > 0 ? space.photos[0] : 'default-thumbnail.jpg'; // Replace 'default-thumbnail.jpg' with your default thumbnail image
  console.log(space)
  console.log(space.contact_person)

  return (
    <Card sx={{ display: 'flex', margin: 2 }}>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={thumbnail}
        alt={"bjork"}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h6">
            {space.type} Office
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            Floor: {space.floor}, Area: {space.area}m²
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default LeasableSpace;
/*
          <Typography variant="body2">
            Contact: {space.contact_person.name}, {space.contact_person.phone}
          </Typography>
*/