import React, { useEffect, useRef, useState } from 'react';
import { forwardRef } from 'react';

const ResponsiveMarker = forwardRef(function MyInput(props, ref) {
  return (
    <div ref={ref}>
      {props.children}
    </div>
  );
});

export default ResponsiveMarker;
