import React, { useState, useRef } from 'react';
import ResponsiveMarker from "./ResponsiveMarker"
import { Map, Marker } from "pigeon-maps"
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { buildings } from "../../data/propertyData";

const initGroups = () => Object.keys(buildings).map(k => [k])

const MapComponent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const mRef = useRef({})
  const [groups, setGroups] = useState(initGroups())

  const getGroups = () => {
    const dist = 75
    const positions = Object.keys(buildings).map(k => {
      const current = mRef.current[k]
      const { x, y } = current.getBoundingClientRect();
      return {id:k, x, y}
    })

    const getDistance = (p1,p2) => Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.x - p2.x, 2))

    const closeTo = pp => positions.filter((p) => pp.id !== p.id && getDistance(p, pp) < dist)

    const groups = positions.map(p => [...closeTo(p), p])

    return groups
  }

  const onZoom = () => {
    const gs = getGroups()
    //console.log(gs)
    setGroups(gs)
  };

  return (
    <Map defaultCenter={cities.helsinki.coordinates} defaultZoom={11} onBoundsChanged={onZoom} >
      { Object.keys(buildings).map((k) => {
        const p = buildings[k]
        const img =
          <img
            alt={p.name}
            width="75px"
            height="75px"
            src={p.thumbnail}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
        const width = 20
        const elem = groups[k].length > 1 ? <Marker width={width} color={colors.primary[300]}/> : img
        return (
          <Marker key={p.name} width={width} anchor={p.coordinates} >
            <ResponsiveMarker ref={el => mRef.current[k] = el} children={elem} />
          </Marker>)
      })}
    </Map>
  )
};

export default MapComponent;

const cities = {
  helsinki: {
    coordinates: [60.192059, 24.945831]
  },
}
