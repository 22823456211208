import { Typography } from "@mui/material";

const Message = ({ text }) => {
  return (
    <Typography style={{ whiteSpace: 'pre-line' }}>
      {text}
    </Typography>
  );
};

export default Message;