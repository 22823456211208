import React, { useState } from 'react';
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { buildings } from "../../data/propertyData";
import Header from "../../components/Header";

const Properties = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const columns = [
    { field: "id", headerName: "ID", hide: true},
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      flex: 1,
    },

    {
      field: "officeCount",
      headerName: "Offices Available",
      flex: 1,
    }
  ];

  // Define the different views for each tab
  const tabsContent = [
    {
      label: 'List View',
      content: (
        <DataGrid
          rows={buildings}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      )
    },
    {
      label: 'Data Grid View',
      content: (
        <div>
          {/* Render your properties as a list here */}
        </div>
      )
    },
    {
      label: 'Map View',
      content: (
        <div>
          {/* Render your properties on a map here */}
        </div>
      )
    }
  ];

  return (
    <Box m="20px" sx={{ color: theme.palette.text.primary }}>
      <Header
        title="PROPERTIES"
        subtitle="List of Imported Properties"
      />


      <Tabs 
        value={tabIndex} 
        onChange={handleTabChange} 
        centered
        sx={{
          ".MuiTabs-indicator": {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
          },
          ".MuiTab-root": {
            color: 'inherit', // Use the inherited text color
            '&.Mui-selected': {
              color: theme.palette.mode === 'dark' ? colors.greenAccent[500] : colors.primary[600], // Adjust selected color
            }
          }
        }}
      >
        {tabsContent.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {tabsContent[tabIndex].content}
      </Box>
    </Box>
  );
};

export default Properties;
