export const buildings = [
  {
    id: 1, // <-- REQUIRED!!
    name: "Kamppi Center",
    description: "Located in Helsinki, the Kamppi Center is a modern shopping and office complex, known for its unique architecture.",
    // for map, remove later
    thumbnail: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Kamppi_Center_II.jpg",
    photos: [
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Kamppi_Center_II.jpg",
      "https://upload.wikimedia.org/wikipedia/commons/b/bf/Interior_on_the_first_floor_of_the_shopping_centre_of_Kamppi_Center_in_Kamppi%2C_Helsinki%2C_Finland%2C_2022_August.jpg",
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Lint%C3%A9rieur_du_Kamppi_%28Helsinki%29_%282790496800%29.jpg"
    ],
    coordinates: [60.1694224435301, 24.933497307540645],
    address: "Kamppi Street 1, 10000",
    city: "Helsinki",
    leasable_spaces: [
      {
        id_office: "01ARZ3NDEKTSV4RRFFQ69G1FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 110,
        floor: 2,
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Office",
        metadata: {
          condition: "Good",
          availability: "September, 2024",
          description: "Ylimmän kerroksen kompakti tehotoimisto, joka remontoidaan kesän aikana. \
          Tilassa keittiö juuri uusittu ja oma terassi. Kulku toimistoon ei ole esteetön, vaan \
          ylimpään kerrokseen noustaan viimeinen kerros portaiden kautta."
        }
      },
      {
        id_office: "03ARZ3NDEKTSV4RRFFQ69G5FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 210,
        floor: 2,
        type: "Retail",
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Office",
        metadata: {
          condition: "Good",
          availability: "September, 2024",
          description: "Ylimmän kerroksen kompakti tehotoimisto, joka remontoidaan kesän aikana. \
          Tilassa keittiö juuri uusittu ja oma terassi. Kulku toimistoon ei ole esteetön, vaan \
          ylimpään kerrokseen noustaan viimeinen kerros portaiden kautta."
        }
      }
    ],
  },
  {
    id: 2,
    name: "Sello Shopping Center",
    description: "Situated in Espoo, the Sello Shopping Center is one of Finland's largest retail complexes, offering a wide range of shops and services.",
    thumbnail: "https://i.media.fi/incoming/k1e4mh/2263888.jpg/alternates/FREE_960/2263888.jpg",
    photos: [
      "https://i.media.fi/incoming/k1e4mh/2263888.jpg/alternates/FREE_960/2263888.jpg",
      "https://www.sttinfo.fi/data/images/00665/1b7e8b1b-bca8-425c-967e-f262fef611a2-w_960.jpg",
      "https://lh3.googleusercontent.com/p/AF1QipPI0GsauVUU7cniGOWEDeh9xNsudxWO1ZK1JNIE=s680-w680-h510",
      "https://www.sttinfo.fi/data/images/00590/cff77df8-64be-4198-97c1-48a47713d8b5-w_960.jpg",
    ],
    coordinates: [60.2182828134694, 24.81079899305051],
    address: "Sello Street 1, 10030",
    city: "Espoo",
    leasable_spaces: 1
  },
  {
    id: 3,
    name: "Iso Omena Shopping Center",
    description: "Based in Espoo, the Iso Omena Shopping Center is a popular shopping destination with a diverse selection of stores and eateries.",
    thumbnail: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Iso_Omena_shopping_centre%2C_Matinkyl%C3%A4%2C_Espoo_%28March_2019%29.jpg/1920px-Iso_Omena_shopping_centre%2C_Matinkyl%C3%A4%2C_Espoo_%28March_2019%29.jpg",
    photos: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2b/Iso_Omena_shopping_centre%2C_Matinkyl%C3%A4%2C_Espoo_%28March_2019%29.jpg/1920px-Iso_Omena_shopping_centre%2C_Matinkyl%C3%A4%2C_Espoo_%28March_2019%29.jpg",
      "https://images.cdn.yle.fi/image/upload/ar_1.7777777910232544,c_fill,g_faces,h_1079,w_1920/dpr_1.0/q_auto:eco/f_auto/fl_lossy/13-3-10764098",
      "https://www.clearchannel.fi/hs-fs/hubfs/Website/Images/product-images/Downtown%20Digital/shopping-wall-iso-omena-2560x1440-c-default.jpg?width=1260&name=shopping-wall-iso-omena-2560x1440-c-default.jpg"

    ],
    coordinates: [60.161678720415296, 24.73826375558204],
    address: "Omena Street 1, 10027",
    city: "Espoo",
    leasable_spaces: 1
  },
  {
    id: 4,
    name: "REDI Shopping Center",
    description: "Located in Helsinki, REDI is a modern and vibrant shopping center featuring a variety of retail stores, restaurants, and entertainment options.",
    thumbnail: "https://images.cdn.yle.fi/image/upload/ar_1.5,c_fill,g_faces,h_1280,w_1920/dpr_1.0/q_auto:eco/f_auto/fl_lossy/v1537438630/39-5111605ba37383a5b4e",
    photos: [
      "https://images.cdn.yle.fi/image/upload/ar_1.5,c_fill,g_faces,h_1280,w_1920/dpr_1.0/q_auto:eco/f_auto/fl_lossy/v1537438630/39-5111605ba37383a5b4e",
      "https://www.redi.fi/wp-content/uploads/2023/05/230512_Redi_dronekuvat-1-pieni-1300x1007.jpg",
      "https://scontent-hel3-1.xx.fbcdn.net/v/t39.30808-6/236872497_5884982081571713_3300000295264979537_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=783fdb&_nc_ohc=KgsFgjGcAWIAX8q0AY0&_nc_ht=scontent-hel3-1.xx&oh=00_AfDeYw7_qXCbu9ZkBEruTE8d2pT_oQkBwjEFrgb7OKT82g&oe=65828FA5"
    ],
    coordinates: [60.18758586435067, 24.97931072084449],
    address: "Pasilankatu 1, 10009",
    city: "Helsinki",
    leasable_spaces: 1
  },
  {
    id: 6,
    name: "Stockmann Department Store",
    description: "Situated in Helsinki, Stockmann is Finland's leading department store offering a wide range of products from fashion to home goods.",
    thumbnail: "https://kiinteistouutiset.fi/wp-content/uploads/2019/05/stockmann_shutterstock.jpg",
    photos: [
      "https://kiinteistouutiset.fi/wp-content/uploads/2019/05/stockmann_shutterstock.jpg",
      "https://www.verkkouutiset.fi/wp-content/uploads/2023/09/131283506-2048x1393.jpg",
      "https://www.discoveringfinland.com/wp-content/uploads/2010/08/6840572259_8b96abde19_b.jpg",
    ],
    coordinates: [60.16841335486199, 24.942171746821252],
    address: "Kalevankatu 1, 10000",
    city: "Helsinki",
    leasable_spaces: [
      {
        id_office: "01ARZ3NDEKTSV4RRFFQ69G6FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 160,
        floor: 2,
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Corporate",
        photos: [
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_4.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_3.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_2.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_1.jpg"
        ],
      }
    ]
  },
  {
    id: 7,
    name: "World Trade Center Helsinki",
    description: "As part of the global network, World Trade Center Helsinki offers state-of-the-art office spaces and a collaborative business environment in the heart of the city.",
    thumbnail: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/WTC_helsinki_%28Embassy_of_Malaysia%29.jpg/1280px-WTC_helsinki_%28Embassy_of_Malaysia%29.jpg",
    photos: [
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/WTC_helsinki_%28Embassy_of_Malaysia%29.jpg/1280px-WTC_helsinki_%28Embassy_of_Malaysia%29.jpg",
      "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_julkisivu_kevat_1140x600_2-1.jpg",
      "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_julkisivu_8-1.jpg",
      "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_julkisivu_2.jpg",
      "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_julkisivu_7.jpg",
      "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_julkisivu_6.jpg",
      "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_julkisivu_5.jpg",
      "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_julkisivu_1.jpg"
    ],
    coordinates: [60.1690703390522, 24.943617061242144],
    address: "Aleksanterinkatu 1, 10000",
    city: "Helsinki",
    leasable_spaces: [
      {
        id_office: "01ARZ3NDEKTSV4RRFFQ69G6FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 160,
        floor: 2,
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Corporate",
        photos: [
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_4.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_3.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_2.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_1.jpg"
        ],
      },
      {
        id_office: "02ARZ3NDEKTSV4RRFFQ69G5FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 210,
        floor: 2,
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Corporate",
        photos: [
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_8.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_7.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_6.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_5.jpg",
        ],
      },
      {
        id_office: "01ARZ3NDEKTSV5RRFFQ69G5FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 45,
        floor: 3,
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Corporate",
        photos: [
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_12.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_11.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_10.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_9.jpg",
        ],
      },
      {
        id_office: "01ARZ3NDEKTSV4RRFFQ69G5FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 125,
        floor: 1,
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Corporate",
        photos: [
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_14.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_13.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_12.jpg",
        ],
      },
      {
        id_office: "01ARZ3NDEKTSV4RRFFQ70G5FAV", 
        id_publisher: "01ARZ3NDHKTUV4RRFFQ69G5FAV",
        area: 203,
        floor: 1,
        contact_person: {
          name: "Matti Meikäläinen",
          phone: "04512345678",
          email: "matti.meikalainen@email.com",
        },
        type: "Corporate",
        photos: [
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_18.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_17.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_16.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_15.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_14.jpg",
          "https://toimitilat.ilmarinen.fi/app/uploads/ilmarinen_aleksanterinkatu_17_sisakuva_13.jpg",
        ],
      }
    ]
  },
  {
    id: 8,
    name: "Tripla",
    description: "Located in Helsinki, Tripla is a large multipurpose complex comprising a shopping center, office spaces, and a hotel, offering a variety of services and amenities.",
    thumbnail: "https://assetmanager-ws.pilkington.com/fileserver.aspx?cmd=get_file&file_id=20146&digest=vYcEpK11NlhDDPhoozOFAQ==&ct=jpeg&file_name=Tripla_212_.jpeg",
    photos: [
      "https://assetmanager-ws.pilkington.com/fileserver.aspx?cmd=get_file&file_id=20146&digest=vYcEpK11NlhDDPhoozOFAQ==&ct=jpeg&file_name=Tripla_212_.jpeg",
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Tripla_complex_and_Pasilansilta_in_Keski-Pasila%2C_Helsinki%2C_Finland%2C_2022_May_-_2.jpg/1920px-Tripla_complex_and_Pasilansilta_in_Keski-Pasila%2C_Helsinki%2C_Finland%2C_2022_May_-_2.jpg",
    ],
    coordinates: [60.1981, 24.9335],
    address: "Pasilankatu 1, 10000",
    city: "Helsinki",
    leasable_spaces: 1
  }
]
