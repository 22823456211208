import { Box } from "@mui/material";
import LeasableSpace from "./LeasableSpace"; // Import the LeasableSpace component

// Assuming `buildings` is imported or passed as a prop
const PropertyWindow = ({ buildings }) => {
  // Flatten the leasable spaces into a single array
  const leasableSpaces = buildings.filter(s => s.leasable_spaces.length > 0);

  return (
    <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 48px)', p: 2 }}>
      {leasableSpaces.map(building => building.leasable_spaces.map(space =>
        <LeasableSpace key={space.id_office} space={ space } />
      ))}
    </Box>
  );
};

export default PropertyWindow;
