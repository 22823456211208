import React, { useState } from 'react';
import { Box, Typography, TextField, InputAdornment, IconButton, useTheme } from "@mui/material";
import SendIcon from '@mui/icons-material/Send'; // Import the send icon

import { tokens } from '../theme'; 
import Message from './Message.jsx'

const ChatWindow = () => {
  const [messages, setMessages] = useState([
    { id: 1, sender: 'user', text: 'Im looking for an office in Helsinki, preferably with an open floor plan.' },
    { id: 2, sender: 'llm', text: 'Sure, I can help with that. Could you please provide me with your company name for a more tailored search?' },
    { id: 3, sender: 'user', text: 'Real Oy.' },
    { id: 4, sender: 'llm', text: 'Here\'s some basic info about your company that I found online:\nBusiness ID: 1234567-8\nAddress: Aleksanterinkatu 4, 00100 Helsinki\nEmployees: 1-4\n...\n\nIs this correct?' },
    { id: 5, sender: 'user', text: 'Yes!' },
    { id: 6, sender: 'llm', text: 'Great! Given your team-size and industry, I would recommend a maximum office space of 50 m² from a maximum of 30 minute drive away from the City center where most of your customers are.'},
    { id: 7, sender: 'llm', text: 'Check my recommendations from the map and let me know if they fit your needs.'}
  ]);
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSend = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputValue.trim()) {
        setMessages([...messages, { id: messages.length + 1, sender: 'user', text: inputValue }]);
        setInputValue('');
      }
      // Here you would typically send the message to the LLM for processing and append the response
    }
  };

  const handleClickSend = () => {
    if (inputValue.trim()) {
      setMessages([...messages, { id: messages.length + 1, sender: 'user', text: inputValue }]);
      setInputValue('');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {/* ... message log ... */}
      <Box sx={{
        p: 2,
        overflowY: 'auto',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2), // Double the gap between messages
      }}>
        {messages.map((message, index) => (
          <Box
            key={index}
            backgroundColor={colors.primary[700]} // Use a color from your theme
            sx={{
              borderRadius: '16px',
              p: theme.spacing(1),
              maxWidth: '80%',
              alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
            }}
          >
            <Message text={message.text} />
          </Box>
        ))}
      </Box>
      {/* input text field */}
      <Box component="form" sx={{ p: 2, borderTop: '1px solid #ccc' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type your message..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleSend} // Use onKeyDown instead of onKeyPress
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickSend}
                  edge="end"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default ChatWindow;
